import { baobabTokens } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: null,
    lpSymbol: 'WKLAY-USDT LP',
    lpAddress: '0x72d3a0c7a70fc2ef27cdb49d5cf9b637b16b72cd',
    token: baobabTokens.weth,
    quoteToken: baobabTokens.usdt,
  },
]

export default priceHelperLps
