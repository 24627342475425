import Metamask from "../../components/Svg/Icons/Metamask";
// import Ronin from "../../components/Svg/Icons/Ronin";
// import WalletConnect from "../../components/Svg/Icons/WalletConnect";
// import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import Kaikas from "../../components/Svg/Icons/Kaikas";
// import MathWallet from "../../components/Svg/Icons/MathWallet";
// import TokenPocket from "../../components/Svg/Icons/TokenPocket";
// import BinanceChain from "../../components/Svg/Icons/BinanceChain";
// import SafePal from "../../components/Svg/Icons/SafePal";
// import Coin98 from "../../components/Svg/Icons/Coin98";
// import Blocto from "../../components/Svg/Icons/Blocto";
// import Brave from "../../components/Svg/Icons/Brave";
// import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
// import Opera from "../../components/Svg/Icons/Opera";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.MetaMask,
        priority: 2,
        href: "https://metamask.io/download/"
    },
    {
        title: "Kaikas",
        icon: Kaikas,
        connectorId: ConnectorNames.Kaikas,
        priority: 1,
        href: "https://chromewebstore.google.com/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?pli=1"
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
