import { MultiCallV2 } from '@pancakeswap/multicall'
import { farmV2FetchFarms, fetchFarmsParams, fetchMasterChefV2Data as _fetchMasterChefV2Data } from './fetchFarms'

export const masterChefAddresses = {
  97: '0xE641b73948FeBbD349C9Fd21C110eA9Dd384179C',
  56: '0xE641b73948FeBbD349C9Fd21C110eA9Dd384179C',
  5: '0xE641b73948FeBbD349C9Fd21C110eA9Dd384179C',
  1001: '0xDe06b727518347f9744c2B5c2Bea6cdaac6cAE2d',
  8217: '0xDA11e185fF18674A633441Ca954D0f55289Cf7E7',
  2021: '0xA14a3778ED736965Fb47303bA1ac1bC57B2f5a61',
}
// 수정
export const nonBSCVaultAddresses = {
  5: '0xE641b73948FeBbD349C9Fd21C110eA9Dd384179C',
  1001: '0x6690Cac04C94647843a6Df9B0C213631134CB708',
  8217: '0x1eC6691bF64B946Ae7d9a657Ac4001e1Ca4Fe3C4',
  2021: '0x1881895Fd353f0675DBaDcd1ef1558cAf4080160',
}

const supportedChainId = [5, 56, 97, 1001, 8217, 2021]

export function createFarmFetcher(multicall: MultiCallV2) {
  const fetchFarms = (params: Omit<fetchFarmsParams, 'masterChefAddresses' | 'multicall'>) => {
    return farmV2FetchFarms({ ...params, multicall, masterChefAddresses })
  }
  return {
    fetchFarms,
    fetchMasterChefV2Data: (isTestnet: boolean) =>
      _fetchMasterChefV2Data({ isTestnet, masterChefAddresses, multicall }),
    isChainSupported: (chainId: number) => supportedChainId.includes(chainId),
  }
}

export * from './types'
export * from './farmsPriceHelpers'
