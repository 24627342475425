import { ChainId, Token } from '@pancakeswap/sdk'
// 수정
export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x90f193b165159345E0E61703Eb6A25480213480f',
  18,
  'FOC',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_GOERLI = new Token(
  ChainId.GOERLI,
  '0x697A539Ef37aC83980af8e59b5055f11d7dFc964',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_SAIGON = new Token(
  ChainId.SAIGON,
  '0xcBff9d27af2e13Df19C9082276764426DC50eFF7',
  18,
  'FOC',
  'FOC Token',
  'https://pancakeswap.finance/',
)

// 수정
export const CAKE_BAOBAB = new Token(
  ChainId.BAOBAB,
  '0x1DAbA112D79Ce9A17282359a1399B649Be700c84',
  18,
  'BTC2',
  'BTC2 Token',
  'https://pancakeswap.finance/',
)

export const CAKE_KLAYTN = new Token(
  ChainId.KLAYTN,
  '0xe551311e95Ab6f1F42422b0f1A5F3CE7e4384ede',
  18,
  'BTC2',
  'BTC2 Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_BAOBAB = new Token(
  ChainId.BAOBAB,
  '0x11556507f121240E160220C87eC2d691833ca13d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_SAIGON = new Token(
  ChainId.SAIGON,
  '0x22Ca52E4A56f7CF58AB0F87B9Fa10663844a00CC',
  18,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_SAIGON = new Token(
  ChainId.SAIGON,
  '0x1a29215512f7eD3994C3B335F67b03E70890ca57',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_BAOBAB = new Token(
  ChainId.BAOBAB,
  '0xfA466Eda2e4c5F533A7770eB8149007a2A3ed131',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_KLAYTN = new Token(
  ChainId.KLAYTN,
  '0xceE8FAF64bB97a73bb51E115Aa89C17FfA8dD167',
  6,
  'oUSDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_SAIGON = new Token(
  ChainId.SAIGON,
  '0xa8C4c48d714FBEB878b68243482aDEc013D28Ffe',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_BAOBAB = new Token(
  ChainId.BAOBAB,
  '0x210bc03f49052169d5588a52c317f71cf2078b85',
  18,
  'oBUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_KLAYTN = new Token(
  ChainId.KLAYTN,
  '0x210bc03f49052169d5588a52c317f71cf2078b85',
  18,
  'oBUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.BAOBAB]: BUSD_BAOBAB,
  [ChainId.KLAYTN]: BUSD_KLAYTN,
  [ChainId.SAIGON]: BUSD_SAIGON,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.GOERLI]: CAKE_GOERLI,
  [ChainId.BAOBAB]: CAKE_BAOBAB,
  [ChainId.KLAYTN]: CAKE_KLAYTN,
  [ChainId.SAIGON]: CAKE_SAIGON,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.BAOBAB]: USDC_BAOBAB,
  [ChainId.SAIGON]: USDC_SAIGON,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.BAOBAB]: USDT_BAOBAB,
  [ChainId.KLAYTN]: USDT_KLAYTN,
  [ChainId.SAIGON]: USDT_SAIGON,
}
